.banner {
  /* height: 0vh; */
  position: relative;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
}
.banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.socialMedia {
  height: 16px;
  width: 16px;
}

.social_absolute {
  position: absolute;
  right: 22%;
  top: 45%;
}

.apple {
  width: 180px;
}
.google {
  height: 90px;
  width: 200px;
}

.caret {
  transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
  .body {
    overflow: hidden;
  }
  .banner {
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.5s ease-in-out;
    height: 500px !important;
  }
  .footer_company,
  p {
    display: flex;
    flex-direction: column;
  }
  .footer_company > p:nth-child(1) {
    font-size: large;
    font-size: 70px;
  }
  .footer_contact {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
  .footer_contact > p:nth-child(2) {
    align-content: stretch;
    font-size: 30px !important;
    margin-left: 25px !important;
    margin-right: 15px !important;
  }
  .provider_medium {
    position: absolute;
    top: 12%;
    width: 70%;
    margin-bottom: 50px;
  }

  .banner_content {
    position: absolute;
    top: 22%;
    left: auto;
    right: auto;
  }
  .banner_content > p:nth-child(2) {
    font-size: 20px;
  }

  .banner_content > p:nth-child(3) {
    width: 70%;
  }

  .banner_link {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: space-around;
  }

  .banner_link > a {
    margin: 5px;
  }
  .banner_link > a:nth-child(1) {
    width: fit-content;
  }
  .banner-socials {
    display: flex;
    flex-direction: column;
  }

  .apple {
    width: 90% !important;
  }
  .google {
    height: 78px !important;
    width: 100% !important;
  }

  .socialMedia {
    height: 40px;
    width: 40px;
  }

  .store {
    margin-top: 30px;
  }

  .chevronFAQ {
    position: absolute;
    left: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.5s ease-in-out;
    height: 650px !important;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    height: 750px !important;
  }

  .banner_link {
    white-space: nowrap !important;
  }

  .banner_socials {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .store {
    margin-left: 70px !important;
  }

  .partner > img {
    width: 80px;
    height: 50px;
  }

  .partner > p {
    font-size: 10px;
    margin-top: 15px !important;
  }

  .apple {
    width: 100px;
  }
  .google {
    height: 50px;
    width: 120px;
  }
}

@media screen and (max-width: 576px) {
  .body {
    overflow: hidden;
  }

  .chevronRightGreen {
    height: 15px;
    width: 15px;
    position: absolute;
    left: 37%;
    bottom: 19%;
  }
  .serviceimage {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 10%;
    bottom: 30%;
  }
  .FAQs > p:nth-child(1) {
    font-size: 30px;
    width: 70%;
  }

  .FAQs > p:nth-child(2) > span {
    position: absolute;
    left: 0%;
  }

  .FAQs > p:nth-child(2) > img {
    width: 15px;
    height: 15px;
    margin-right: 65px;
    margin-top: 7px;
  }

  .banner_socials {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 60px !important;
  }

  .social_absolute {
    display: flex !important;
    right: 10% !important;
    top: 45% !important;
  }

  .socialMedia {
    height: 12px;
    width: 12px;
  }

  .apple {
    width: 90% !important;
    height: 70px !important;
  }
  .google {
    height: 100px !important;
    width: 100% !important;
  }

  .store {
    margin: auto !important;
  }

  .partner {
    margin: auto !important;
    margin-left: auto !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .partner > p {
    font-size: 14px !important;
  }
}
