.open {
  max-height: 300px;
  transition: max-height 0.3s ease-in;
}
.close {
  max-height: 55px;
  transition: max-height 0.3s ease-in;
}

.animateIn {
  transition: opacity 0.6s ease-in;
}
.animateOut {
  transition: opacity 0.2s ease-in;
}
.scroll {
  background-color: white !important;
  position: fixed !important;
}

@media screen and (min-width: 1024px) {
  .SignUp {
    white-space: nowrap !important;
  }

  .navmenu > p {
    margin-right: 25px !important;
  }

  .navmenu {
    margin-left: 80px;
  }
  .signUpButton {
    height: 40px !important;
    padding: 3px !important;
    width: -webkit-fill-available !important;
  }
}

@media screen and (min-width: 768px) {
  .signUpButton {
    height: 40px !important;
    padding: 3px !important;
    width: -webkit-fill-available !important;
  }
}

@media screen and (max-width: 768px) {
  .SignUp {
    white-space: nowrap !important;
  }

  .navmenu > p {
    margin-right: 20px !important;
  }

  .navmenu {
    position: absolute;
    left: 43%;
    top: 25%;
  }

  .menuLogo > img {
    width: 126px;
    position: absolute;
    top: 27%;
    left: 2.4%;
  }

  .menubutton {
    position: absolute;
    right: 1%;
  }

  .menubutton > button {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 576px) {
  .hamburger {
  }

  /* LginSignUp modal for mobile */
  .LoginSignUp {
    z-index: 1000 !important;
    height: 100% !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .login {
    background-color: #69b42d !important;
    border-radius: 0.25rem;
    border: 1px solid #69b42d;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 5px;
  }

  .menuLogo > img {
    position: absolute;
    top: 5%;
  }
  .navmenu {
    height: 100vh;
    width: 100vw;
    display: block;
    margin: 50px;
  }
  .navmenu > p {
    margin-right: 30px;
  }

  .menubutton {
    display: block;

    position: absolute;
    bottom: 0%;
  }
}
