.open {
  max-height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
  visibility: visible;
  opacity: 1;
  transition: opacity 1.5s ease-out, visibility 0.3s ease-out,
    padding 0.3s ease-out, max-height 0.4s ease-out;
}
@media screen and (min-width: 768px) {
  .open {
    max-height: 300px;
    height: auto;
  }
}
.close {
  max-height: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out,
    padding 0.3s ease-in, max-height 0.4s ease-out;
}

/* FOOTER RESPONSIVENESS */
@media screen and (max-width: 576px) {
  .footer_contact > p:nth-child(2) {
    width: 100%;
    margin-left: 20px;
  }
}
